<template>
  <v-slide-group multiple show-arrows>
    <v-slide-item v-for="(item, i) in items" :key="i">
      <v-col>
        <img width="180" :src="item.src" />
      </v-col>
    </v-slide-item>
  </v-slide-group>
</template>

<script>
export default {
  name: "CompaniesBlock",
  data() {
    return {
      items: [
        { src: require("@/assets/landing/c1.jpg") },
        { src: require("@/assets/landing/c2.jpg") },
        { src: require("@/assets/landing/c3.jpg") },
        { src: require("@/assets/landing/c4.jpg") },
        { src: require("@/assets/landing/c5.jpg") },
        { src: require("@/assets/landing/c6.jpg") },
      ],
    };
  },
};
</script>

<style scoped></style>

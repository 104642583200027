<template>
  <v-col cols="12" md="6" lg="3" class="px-16 px-md-0">
    <v-row>
      <v-col
        cols="12"
        lg="3"
        class="px-10 px-lg-0 text-center text-md-right pt-10 pt-md-4"
      >
        <v-icon class="light-green accent-4 pa-5 " large dark>
          {{ icon }}
        </v-icon>
      </v-col>
      <v-col cols="12" md="9" class=" text-center text-sm-left">
        <div class="text-h5">{{ title }}</div>
        <div class="text-body-1 mt-2">
          {{ description }}
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "AdvantageItem",
  props: {
    icon: {
      type: String,
      default: "la-plane",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>

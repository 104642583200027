<template>
  <v-container fluid>
    <v-row class="bg-plane" align="center">
      <search-block class="mt-16 mb-0 mb-sm-8"></search-block>
    </v-row>
    <v-lazy
        v-model="prosActive"
        min-height="50"
        transition="fade-transition"
        :options="{ threshold: 1 }"
    >
      <v-row class="py-16 grey lighten-4" align="center">
        <advantages-block></advantages-block>
      </v-row>
    </v-lazy>
    <v-lazy v-model="supportActive"
            min-height="50"
            transition="scale-transition"
            :options="{ threshold: 1 }">
      <v-row class="py-16 banner grey lighten-4" align="center" justify="center">
        <a href="https://business.bpo.travel/?utm_source=site&utm_medium=banner&utm_campaign=banner_bpo">
          <img src="../../assets/banner.png">
        </a>
      </v-row>
    </v-lazy>
    <v-lazy
        v-model="supportActive"
        min-height="50"
        transition="scale-transition"
        :options="{ threshold: 1 }"
    >
      <v-row class="py-16 bg-support" align="center" justify="end">
        <support-block></support-block>
      </v-row>
    </v-lazy>
    <v-row class="py-16 grey lighten-4" align="center" justify="center">
      <companies-block></companies-block>
    </v-row>
  </v-container>
</template>

<script>
import AdvantagesBlock from "@/components/Home/AdvantagesBlock";
import CompaniesBlock from "@/components/Home/CompaniesBlock";
import SupportBlock from "@/components/Home/SupportBlock";
import SearchBlock from "@/components/Home/SearchBlock";

export default {
  name: "Landing",
  components: {
    SearchBlock,
    CompaniesBlock,
    SupportBlock,
    AdvantagesBlock,
  },
  data() {
    return {
      showHeader: false,
      prosActive: false,
      supportActive: false,
    };
  },
};
</script>

<style scoped>
.bg-plane {
  background-image: url("~@/assets/landing/bg.jpg");
  background-size: cover;
}

@media only screen and (max-width: 1265px) {
  .bg-plane {
    background-image: url("~@/assets/landing/bg-md.jpg");
    background-size: cover;
  }
}

@media only screen and (max-width: 600px) {
  .bg-plane {
    background-image: url("~@/assets/landing/bg-phone.jpg");
    background-size: cover;
  }
}

.bg-support {
  background-image: url("~@/assets/landing/support3.png");
  background-size: contain;
  background-position: 25% center;
}

@media only screen and (max-width: 960px) {
  .bg-support {
    background-image: none;
    background-color: white;
  }
}
.banner{
  width: 100vw;
}
.banner a{
  max-width: 1040px;
  overflow: hidden;
  margin: 0;
}

.banner img{
  width: 100%;
}
</style>

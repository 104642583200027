<template>
  <v-container class="px-0">
    <v-row justify="space-between">
      <advantage-item
        icon="la-search"
        title="Поисковая система для путешествий по всему миру"
        description="Вы можете использовать нашу поисковую систему, чтобы найти любой
              рейс, который вы хотите, и выбрать желаемое направление и цену."
      ></advantage-item>
      <advantage-item
        icon="la-plane"
        title="Дешевые и выгодные авиабилеты"
        description="Мы предлагаем доступные билеты на рейсы всех существующих
              авиакомпаний, поэтому вам не нужно их искать."
      ></advantage-item>
      <advantage-item
        icon="la-credit-card"
        title="Удобный для вас способ оплаты"
        description="Вы можете оплатить ваш заказ кредитной картой либо наличными."
      ></advantage-item>
    </v-row>
  </v-container>
</template>

<script>
import AdvantageItem from "@/components/Home/AdvantageItem.vue";
export default {
  name: "AdvantagesBlock",
  components: {
    AdvantageItem,
  },
};
</script>

<style scoped></style>

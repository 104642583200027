<template>
  <v-col cols="12" md="6" class="px-16">
    <div class="light-blue--text text-h5">
      Служба поддержки
    </div>
    <div class="font-weight-bold text-h5 text-sm-h4 text-lg-h3 mt-6">
      {{ $store.state.company.Phone }}
    </div>
    <div class="text-body-1 my-8">
      Наша линия поддержки готова ответить на все ваши вопросы и помочь вам в любой ситуации.
    </div>
    <v-btn
      x-large
      color="orange"
      dark
      elevation="5"
      v-bind:href="$store.state.company.PhoneLink"
    >
      Позвонить
    </v-btn>
  </v-col>
</template>

<script>
export default {
  name: "SupportBlock",
};
</script>

<style scoped></style>

<template>
  <v-container class=" pt-lg-16" id="find-tkt-desctop">
    <v-row align="center" class="pb-0 pb-sm-10">
      <v-col cols="12" lg="6" class="text-left">
        <div
          class="
            white--text
            text-h5 text-sm-h3
            font-weight-bold
            pr-16
            pl-16 pl-md-0
          "
        >
          Полет ждет Вас
        </div>
        <div class="white--text text-body-1 mt-10 pr-16 pl-16 pl-md-0">
          С {{ $store.state.company.Name }} вы можете легко забронировать любой
          билет, который вам нужен для безопасного путешествия, благодаря нашей
          системе поиска и бронирования авиа-билетов.
        </div>
        <div class="pl-16 pl-md-0">
          <v-hover v-slot="{ hover }">
            <v-btn
              :outlined="!hover"
              to="/about"
              :dark="!hover"
              large
              class="mt-10"
            >
              Узнать больше
            </v-btn>
          </v-hover>
        </div>
      </v-col>

      <v-col
        cols="12"
        lg="6"
        class="mt-16 mt-md-0 px-0 px-sm-10 px-md-0 pb-0"
        id="find-tkt-phone"
      >
        <search-ticket-form></search-ticket-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SearchTicketForm from "@/components/Home/SearchTicketForm.vue";

export default {
  name: "SearchBlock",
  components: {
    SearchTicketForm,
  },
  data() {
    return {
      valid: false,
      failed: false,
      loading: false,
      oneway: true,
      menu1: false,
      menu2: false,
      directOnly: false,
      dateFrom: new Date().toISOString().substr(0, 10),
      minDate: new Date().toISOString().substr(0, 10),
      dateTo: null,
      adults: 1,
      kids: 0,
      babe: 0,
      searchDep: null,
      searchArr: null,
      cities: [],
      itemsDep: [],
      itemsArr: [],
      arrCity: null,
      dataRules: [(v) => !!v || "Выберите дату"],
      depCity: null,
      cityRules: [(v) => !!v || "Выберите город"],
      fClass: "Э",
      classes: [
        {
          text: "Эконом",
          value: "Э",
        },
        {
          text: "Бизнес",
          value: "Б",
        },
      ],
    };
  },
};
</script>

<style scoped>
.bg-image {
  background-image: url("~@/assets/landing/bg.jpg");
  background-size: cover;
}
</style>

<template>
  <v-card v-if="developmentStatus" dark rounded flat class="px-10 pb-10 pt-0 pt-sm-10 form-back">
        Ведутся работы
  </v-card>

  <v-card v-else dark rounded flat class="px-10 pb-10 pt-0 pt-sm-10 form-back">
    <v-row v-if="loading">
      <v-col>
        <hos-progress-circular
            :opacity="0.7"
            absolute
            :loading="loading"
        ></hos-progress-circular>
      </v-col>
    </v-row>
    <v-form v-model="valid" ref="form" action="/found-tickets" @submit="submit">
      <v-row>
        <v-col>
          <v-radio-group v-model="oneway" row>
            <v-radio
                label="В одну сторону"
                color="light-green accent-4"
                class="mt-5 mt-sm-0"
                :value="true"
            >
            </v-radio>
            <v-radio
                label="Туда обратно"
                color="light-green accent-4"
                class="mt-5 mt-sm-0"
                :value="false"
            >
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <label>Откуда</label>
          <v-autocomplete
              v-model="depCity"
              :items="depCities"
              :search-input.sync="searchDepCity"
              return-object
              :rules="cityRules"
              auto-select-first
              label="Откуда"
              required
              ref="input_from"
              flat
              solo
              light
              no-data-text="Начните вводить город"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6">
          <label>Куда</label>
          <v-autocomplete
              v-model="arrCity"
              :items="arrCities"
              :search-input.sync="searchArrCity"
              return-object
              :rules="cityRules"
              auto-select-first
              hide-details
              label="Куда"
              required
              flat
              solo
              light
              no-data-text="Начните вводить город"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="pt-0">
          <custom-date-picker
              v-model="date.From"
              :min="date.Today"
              color="light-green accent-4"
              format="dd.MM.yyyy"
              solo
              light
          ></custom-date-picker>
        </v-col>
        <v-col cols="12" md="6" class="pt-0">
          <custom-date-picker
              v-model="date.To"
              :min="date.From"
              :disabled="oneway"
              color="light-green accent-4"
              format="dd.MM.yyyy"
              solo
              light
          >
          </custom-date-picker>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" md="4" class="py-0">
          <label>Взрослые</label>
          <v-text-field
              type="number"
              min="0"
              required
              label="Взрослые"
              v-model="adults"
              flat
              solo
              light
              color="light-green accent-4"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6" md="4" class="py-0">
          <label>Дети</label>
          <v-text-field
              type="number"
              min="0"
              label="Дети"
              v-model="kids"
              flat
              solo
              light
              color="light-green accent-4"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6" md="4" class="py-0">
          <label>Младенцы</label>
          <v-text-field
              type="number"
              min="0"
              label="Младенцы"
              v-model="babe"
              flat
              solo
              light
              color="light-green accent-4"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6" md="12" class="py-0">
          <label>Класс</label>
          <v-select
              label="Класс"
              :items="classes"
              v-model="fClass"
              item-color="light-green accent-4"
              flat
              solo
              light
              color="light-green accent-4"
          ></v-select>
        </v-col>
      </v-row>

      <v-row class="" align="center">
        <v-col cols="12">
          <v-btn block dark type="submit" color="orange" large elevation="5">
            Найти Рейс
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import CustomDatePicker from "@/components/UI/CustomDatePicker.vue";
import HosProgressCircular from "@/components/UI/HosProgressCircular";
import DateFormat from "@/mixins/DateFormat";
import {format} from "date-fns";

export default {
  components: {CustomDatePicker, HosProgressCircular},
  name: "SearchForm",
  mixins: [DateFormat],
  data() {
    return {
      loading: false,
      date: {
        From: new Date(),
        To: new Date(),
        Today: new Date(),
      },
      isWithoutTransfers: false,
      searchDepCity: null,
      searchArrCity: null,
      valid: false,
      oneway: true,
      adults: 1,
      kids: 0,
      babe: 0,
      departureCities: [],
      arrivalCities: [],
      arrCity: "",
      depCity: "",
      cityRules: [(v) => !!v || "Выберите город"],
      fClass: 0,
      developmentStatus: false,
      classes: [
        {text: "Эконом", value: 0},
        {text: "Бизнес", value: 1},
      ],
      timerFilter: null,
    };
  },
  computed: {
    depCities: {
      get() {
        return this.sortCitiesArray(this.departureCities, this.searchDepCity);
      },
      set(newValue) {
        this.departureCities = newValue;
      }
    },
    arrCities: {
      get() {
        return this.sortCitiesArray(this.arrivalCities, this.searchArrCity);
      },
      set(newValue) {
        this.arrivalCities = newValue;
      }
    }
  },
  watch: {
    searchDepCity: {
      handler() {
        clearTimeout(this.timerFilter);
        this.timerFilter = setTimeout(async () => {
          this.depCities = await this.getStations(this.searchDepCity);
        }, 300);
      },
    },
    searchArrCity: {
      handler() {
        clearTimeout(this.timerFilter);
        this.timerFilter = setTimeout(async () => {
          this.arrCities = await this.getStations(this.searchArrCity);
        }, 300);
      },
    },
  },
  methods: {
    sortCitiesArray(array, val) {
      return array.sort(function (a, b) {
        if (a.text.slice(0, val.length) === val && b.text.slice(0, val.length) != val) {
          return -1;
        }
        if (a.text.slice(0, val.length) != val && b.text.slice(0, val.length) === val) {
          return 1
        }
        if (a.text.slice(0, val.length) === val && b.text.slice(0, val.length) === val) {
          if (a.text < b.text) {
            return -1;
          }
          if (a.text > b.text) {
            return 1;
          }
        }
        return 0;
      });
    },
    async getStations(val) {
      if (val === null || val === "") return [];
      let stations = [];
      let resp = await this.$axios.get(this.$enums.Endpoints.Data.Stations + `?text=${val}`);
      resp.data.forEach((e) => {
        stations.push({
          text: e.nameRu,
          value: {
            code: e.codeEn,
            name: e.nameRu,
          },
        });
      });
      return stations;
    },
    submit(event) {
      event.preventDefault();
      if (this.$refs.form.validate()) {
        if (!this.isRailway) {
          this.$router.push(
              `/found-tickets?src=${this.depCity.value.code}&dest=${
                  this.arrCity.value.code
              }&date=${format(this.date.From, "yyyy-MM-dd")}&back=${
                  this.oneway ? "" : format(this.date.To, "yyyy-MM-dd") ?? ""
              }&cls=${this.fClass}&adults=${this.adults}&kids=${this.kids}&bbs=${
                  this.babe
              }&city1=${this.depCity.value.name}&city2=${this.arrCity.value.name}`
          );
        } else {
          this.$router.push(
              `/trs-list?src=${this.depCity.text}&dest=${this.arrCity.text}&codeSrc=${this.depCity.value}&codeDest=${this.arrCity.value}&date=${this.date.From}&adults=${this.adults}&kids=${this.kids}&bbs=${this.babe}`
          );
        }
      }
    },
  },
};
</script>

<style>
.form-back {
  background: rgb(116, 0, 242);
  background: linear-gradient(
      352deg,
      rgba(116, 0, 242, 1) 0%,
      rgba(41, 156, 246, 1) 65%,
      rgba(41, 182, 246, 1) 100%
  );
}
</style>
